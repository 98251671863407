/* eslint-disable semi */
/* eslint-disable quotes */
import "slick-carousel";
import $ from "jquery";

import "animate.css/animate.min.css";
import "slick-carousel/slick/slick.css";

document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener("click", function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute("href")).scrollIntoView({
      behavior: "smooth",
    });
  });
});

$(document).scroll(function () {
  var header = $(".header-content").height();
  var scrolled = $(document).scrollTop();
  var opacity = 1 - (scrolled / header) * 2;
  var blur = (scrolled / header) * 10;
  if (blur < 10) {
    $(".header-title, .header-btn-group").css({
      filter: "blur(" + blur + "px)",
    });
  }

  $(".header-title, .header-btn-group").css({
    opacity: opacity,
  });
});

$(window).scroll(function () {
  if ($(window).scrollTop() > 56) {
    $(".main-nav").addClass("bg-white shadow-md bg-opacity-100");
    $(".main-nav").removeClass("bg-black bg-opacity-60");

    $(".nav-links a").addClass("text-gray-900");
    $(".nav-links a").removeClass("text-white");

    $(".white-logo").addClass("hidden");
    $(".white-logo").removeClass("block");

    $(".dark-logo").addClass("block");
    $(".dark-logo").removeClass("hidden");

    $("#menu-btn").addClass("text-gray-900");
    $("#menu-btn").removeClass("text-white");
  } else {
    $(".main-nav").removeClass("bg-white shadow-md bg-opacity-100");
    $(".main-nav").addClass("bg-black bg-opacity-60");

    $(".nav-links a").removeClass("text-gray-900");
    $(".nav-links a").addClass("text-white");

    $(".white-logo").removeClass("hidden");
    $(".white-logo").addClass("block");

    $(".dark-logo").removeClass("block");
    $(".dark-logo").addClass("hidden");

    $("#menu-btn").removeClass("text-gray-900");
    $("#menu-btn").addClass("text-white");
  }
});

$(window).scroll(function () {
  if ($(window).scrollTop() > 600) {
    $("#float-block").addClass("sticky opacity-100");
    $("#float-block").removeClass("fixed opacity-0");
  } else {
    $("#float-block").removeClass("sticky opacity-100");
    $("#float-block").addClass("fixed opacity-0");
  }
});

$("#menu-btn").click(function (e) {
  e.preventDefault();
  $("#mobile-menu").slideToggle();
  $(this).toggleClass("open");
});

$(function () {
  $("#partners-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 6000,
    arrows: true,
    dots: false,
    cssEase: "linear",
    Infinite: true,
    prevArrow: $(".prev-btn"),
    nextArrow: $(".next-btn"),
  });
});
